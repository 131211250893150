import { SlackSocketModeConnections } from '../pages/Admin/Slack';

function SlackRoute() {
  return <SlackSocketModeConnections />;
}

export const Component = SlackRoute;

export function clientLoader() {
  return null;
}
